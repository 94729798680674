import React, { useState, useEffect } from 'react';
import Informacion from './Informacion';
import clientAxios from '../../config/clientAxios';
import { useParams } from 'react-router-dom';
import Cargando from '../extras/Cargando';
import Error from '../extras/Error_500';
import { toSlug } from '../extras/Funciones';
import Back from '../extras/Back';

const ListadoInformacion = () => {

    const [subtema, setSubtema] = useState({});
    const [informacion, setInformacion] = useState([]);
    const [status, setStatus] = useState(null);
    //parametros
    const { subtema_id } = useParams();

    useEffect(() => {
        const getInformacion = async () => {
            await clientAxios.get(`catalogo/getInformacion/${subtema_id}`, { timeout: 10000 })
                .then(response => {
                    setSubtema(response.data.subtema);
                    setInformacion(response.data.informacion);
                    setStatus(true);
                }).catch(error => {
                    console.log(error);
                    setStatus(false);
                })
        }
        getInformacion();
    }, [subtema_id]);

    return (
        <React.Fragment>
            <div className="container animated fadeIn">
                <title>SIEM | COEPLA</title>
                <br></br>
                <div className='row'>
                    <div className='col-lg-3 content-left'>
                        <Back />
                    </div>
                </div>
                <div className="row justify-content-center">
                    {status === true ?
                        <div className="col-lg-12">
                            <div className="row ">
                                <div className="col-lg-12 mb-3 text-center">
                                    <br></br>
                                    <span align="center " className={`btn nohover btn-block btn-${toSlug(subtema.subsistema)}`}>
                                        <h3>
                                            <img src={'/images/icons/' + subtema.file_name + '.png'} width='9%' height='9%' alt='Información'></img>
                                            {subtema.name}
                                        </h3>
                                    </span>
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <hr></hr>
                                </div>
                            </div>
                            <div className='row'>
                                {informacion.map(info => {
                                    return (
                                        <Informacion
                                            titulo={info.name}
                                            fuente={info.fuente}
                                            tema={toSlug(subtema.subsistema)}
                                            key={info.id}
                                            nombre={info.nombre}
                                            descripcion={info.descripcion} />
                                    )
                                })}
                            </div>
                            <div className="col-lg-12 mb-3">
                                <br></br>
                            </div>
                        </div>
                        : status === false ? <Error></Error> : <Cargando></Cargando>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default ListadoInformacion;