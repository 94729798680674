//Municipio seleccionado
export const SET_MUNICIPIO = 'SET_MUNICIPIO';
export const RESET_MUNICIPIO = 'RESET_MUNICIPIO';
//Año Seleccionado
export const SET_YEAR = 'SET_YEAR';
export const RESET_YEAR = 'RESET_YEAR';

//Mes Seleccionado
export const SET_MONTH = 'SET_MONTH';
export const RESET_MONTH = 'RESET_MONTH';

//Columna Seleccionada
export const SET_COLUMN = 'SET_COLUMN';
export const RESET_COLUMN = 'RESET_COLUMN';
//Scroll top
export const SET_SCROLL = 'SET_SCROLL';
export const RESET_SCROLL = 'RESET_SCROLL';