import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/NotFound.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import Back from './Back';

const NotFound = () => {
    return (
        <div className='container'>
            <div>
                <div className='row'>
                    <div className='col-lg-2 mt-3'>
                        <Back/>
                    </div>
                    <div className='col-lg-10'></div>
                </div>
            </div>
            <section className="page_404">
                <div className="row">
                    <div className="col-lg-12 ">
                        <div className="col-lg-12 col-lg-offset-1  text-center">
                            <div className="four_zero_four_bg">
                                <h1 className="text-center">Página no encontrada</h1>
                            </div>
                            <br></br>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-md-center'>
                    <div className='col-lg-4'>
                        <Link to='/' className='btn btn-lg btn-primary btn-block'>
                            <FontAwesomeIcon icon={faHome} /> Ir al Inicio
                        </Link>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default NotFound;
