import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faMap, faChartLine, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import Logo from '../../assets/gallery/logos/Logo';

const Menu = () => {
    return (
        <React.Fragment>
            <div className="row text-black mt-2 mb-2" style={{ background: '#FFFFFF' }}>
                <div className="col-lg-3 text-center align-self-center"></div>
                <div className="col-lg-6 text-center align-self-center">
                    <h3>
                        <strong>Sistema de Información Estadístico Municipal</strong>
                    </h3>
                </div>
                <div className="col-lg-3 text-center align-self-center">
                    <Logo
                        key='coepla'
                        src='planeacion-paz.png'
                        alt='Logo COEPLA'
                        width='70%'
                        height='70%'
                    ></Logo>
                </div>
            </div>
            <nav className="navbar navbar-expand-lg navbar-light" style={{ background: '#b2b2b2' }}>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent" >
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/" className="nav-link link text-white">
                                <FontAwesomeIcon icon={faHome} /> <b>Inicio</b>
                            </Link>
                        </li>
                        <li className="nav-item" style={{marginLeft: '1rem', marginRight: '1rem'}}>
                            <a className="nav-link link text-white" target="_blank" href="https://cieg.zacatecas.gob.mx" rel="noopener noreferrer" style={{ color: `#7D878E`, textDecoration: `none` }}>
                                <FontAwesomeIcon icon={faChartLine} /> <b>CIEG</b>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link link text-white" target="_blank" href="http://sige.zacatecas.gob.mx" rel="noopener noreferrer" style={{ color: `#7D878E`, textDecoration: `none` }}>
                                <FontAwesomeIcon icon={faMap} /> <b>Servicio de Información Georreferenciada</b>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </React.Fragment>
    );
}

export default Menu;