import React, { useEffect, useState } from 'react';
import clientAxios from '../../../../config/clientAxios';
import { Line } from 'react-chartjs-2';
import { lineOptions } from '../../../extras/Funciones';
import Cargando from '../../../extras/Cargando';
import Error from '../../../extras/Error_500';
//Redux
import { useSelector } from 'react-redux';

const LineChart = ({ globals, columns }) => {
    const [options, setOptions] = useState();
    const { municipio } = useSelector(state => state.General);
    const [status, setStatus] = useState(undefined);
    const [data, setData] = useState({
        datasets: [{
            data: [],
            backgroundColor: [],
            type: null
        }],
        labels: null
    });

    useEffect(() => {
        const getData = async () => {
            await clientAxios.post(`${globals.route}/graphLine/${municipio.id}`, { columnas: columns })
                .then(response => {
                    setData(response.data.objectLine);
                    setStatus(true);
                })
                .catch(error => {
                    setStatus(false);
                    console.log(error);
                });
        }
        getData();
        setOptions(lineOptions(globals.title, globals.symbol, globals.atZero));
    }, []);//eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {status === true ?
                <Line data={data} options={options} />
                : status === false ? <Error /> : <Cargando />
            }
        </div>
    );
}

export default LineChart;