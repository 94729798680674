import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Error_500 = () => {
    return (
        <div className="container" id='error_500'>
            <div className='row justify-content-center'>
                <div className='col-lg-12 mb-3 text-center'>
                    <br></br>
                    <div className="alert alert-danger" style={{ background: '#E9B1B6' }} role="alert">
                        <h3>Error de conexión al servidor</h3>
                        <br></br>
                        <div>
                            Algo saló mal, contacte al administrador de esta página.
                            <br></br>
                            <div className='col-lg-12'>
                                <span className="badge badge-pill badge-secondary">
                                    <FontAwesomeIcon icon={faPhone} size="1x"></FontAwesomeIcon>
                                </span>
                                &nbsp;Sistema Estatal de Información: (01-492) 4915052 Ext. 10451
                            </div>
                            <div className='col-lg-12'>
                                <span className="badge badge-pill badge-secondary">
                                    <FontAwesomeIcon icon={faPhone} size="1x"></FontAwesomeIcon>
                                </span>
                                &nbsp;Informática: (01-492) 4915052 Ext. 10504
                            </div>
                        </div>
                        <hr />
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faEnvelope} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;Email: sistemas.coepla@zacatecas.gob.mx
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Error_500;