import { 
    SET_MUNICIPIO, RESET_MUNICIPIO,
    SET_YEAR, RESET_YEAR,
    SET_MONTH, RESET_MONTH,
    SET_COLUMN, RESET_COLUMN, SET_SCROLL, RESET_SCROLL
} from '../../types/General';

//Municipio seleccionado
export const setMunicipio = (municipio) => {
    return {
        type: SET_MUNICIPIO,
        payload: municipio
    };
}

export const resetMunicipio = () => {
    return {
        type: RESET_MUNICIPIO
    };
}
//Ejercicio Seleccionado
export const setYear = (year) => {
    return {
        type: SET_YEAR,
        payload: year
    }
}

export const resetYear = () => {
    return {
        type: RESET_YEAR
    }
}

//Month Seleccionado
export const setMonth = (month) => {
    return {
        type: SET_MONTH,
        payload: month
    }
}

export const resetMonth = () => {
    return {
        type: RESET_MONTH
    }
}

//Columna seleccionada
export const setColumn = (column) => {
    return {
        type: SET_COLUMN,
        payload: column
    }
}

export const resetColumn = () => {
    return {
        type: RESET_COLUMN
    }
}

//Scroll
export const setScroll = () => {
    return {
        type: SET_SCROLL,
        payload: true
    }
}
export const resetScroll = () => {
    return {
        type: RESET_SCROLL
    }
}