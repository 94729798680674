import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toSlug } from '../extras/Funciones';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Informacion = ({ titulo, fuente, tema, nombre, descripcion }) => {
    return (
        <React.Fragment>
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-3 mt-3">
                <div className="btn-group btn-group-md btn-block align-middle" role="group" >
                    <Link to={`/${toSlug(nombre)}`} className={"btn btn-" + tema} style={{ width: '90%' }}>
                        &nbsp;{titulo} &nbsp;&nbsp;&nbsp;<small><small><strong><i className='text-white'>{fuente.nombre}</i></strong></small></small>
                    </Link>
                    <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip(descripcion)}>
                        <button className={"btn btn-" + tema} style={{ width: '10%', opacity: 0.8 }}>
                            <FontAwesomeIcon icon={faInfoCircle} color='white'></FontAwesomeIcon>
                        </button>
                    </OverlayTrigger>

                </div>
            </div>
            <div className="col-lg-2"></div>
        </React.Fragment>
    );
}

const renderTooltip = ( description ) => (
    <Tooltip id="button-tooltip">
        {description}
    </Tooltip>
);

export default Informacion;