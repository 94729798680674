import {
    SET_MUNICIPIO, RESET_MUNICIPIO,
    SET_YEAR, RESET_YEAR,
    SET_MONTH, RESET_MONTH,
    SET_COLUMN, RESET_COLUMN, SET_SCROLL, RESET_SCROLL
} from '../../types/General';

const InitialState = {
    municipio: '',
    year: null,
    month: {id: null, nombre: null},
    column: {key: null, value: null},
    rango: {},
    columns: [],
    years: [],
    scroll: false
};

export default function general(state = InitialState, action) {
    switch (action.type) {
        //Id de municipio
        case SET_MUNICIPIO:
            return {
                ...state,
                municipio: action.payload
            }
        case RESET_MUNICIPIO:
            return {
                ...state,
                municipio: ''
            }
        //Ejercicio a consultar
        case SET_YEAR:
            return {
                ...state,
                year: action.payload
            }
        case RESET_YEAR:
            return {
                ...state,
                year: null
            }
        //Month a consultar
        case SET_MONTH:
            return {
                ...state,
                month: action.payload
            }
        case RESET_MONTH:
            return {
                ...state,
                month: {id: null, nombre: null}
            }
        //atributo de la tabla a graficar
        case SET_COLUMN:
            return {
                ...state,
                column: action.payload
            }
        case RESET_COLUMN:
            return {
                ...state,
                column: {key: null, value: null}
            }
        case SET_SCROLL:
            return {
                ...state,
                scroll: action.payload
            }
        case RESET_SCROLL:
            return {
                ...state,
                scroll: false
            }
        default:
            return state;
    }
}