import React from 'react';

const Cargando = () => {
    return (
        <div className="container" id='cargando'>
            <div className='row justify-content-center'>
                <div className='col-lg-12 mb-3 text-center'>
                    <br></br>
                    <img src='/images/icons/cargando.gif' width='15%' height='70%' alt='Cargando...'></img>
                </div>
            </div>
        </div>
    );
}

export default Cargando;