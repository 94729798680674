
export const toSlug = function (string) {
    if (string) {
        return string
            .toLowerCase()
            .replace(/ /g, '-')
            //.replace(/[^\w-]+/g, '')
            .replace(/\(/gi, "")
            .replace(/\)/gi, "")
            .replace(/,/gi, "")
            .replace(/á/gi, "a")
            .replace(/é/gi, "e")
            .replace(/í/gi, "i")
            .replace(/ó/gi, "o")
            .replace(/ú/gi, "u")
            .replace(/ñ/gi, "n");
    } else {
        return '';
    }
}

export const getClave = function (municipio_id) {
    if (municipio_id < 10) {
        return `3200${municipio_id}`;
    } else if (municipio_id < 100) {
        return `320${municipio_id}`;
    } else {
        return `32${municipio_id}`;
    }
}

export const barOptions = function (title, symbol = '', atZero = false) {
    return {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    beginAtZero: atZero,
                    callback: function (value, index, values) {
                        return value.toLocaleString('en-US')+symbol;
                    },
                }
            }]
        },
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return `${title}: ${(tooltipItem.yLabel).toLocaleString('en-US')}`;
                }
            },
            mode: 'index',
            intersect: true
        }
    }
}

export const lineOptions = function (title, symbol = '', atZero = false) {
    return {
        responsive: true,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: atZero,
                        callback: function (value, index, values) {
                            return value.toLocaleString('en-US')+symbol;
                        }
                    }
                },
            ],
        },
        legend: {
            display: true,
        },
        tooltips: {
            callbacks: {
                title: function(tooltipItem, data) {
                    return `${title} ${tooltipItem[0].xLabel}`;
                },
                label: function (tooltipItem, data) {
                    return `${data.datasets[tooltipItem.datasetIndex].label}: ${(tooltipItem.yLabel).toLocaleString('en-US')}${symbol}`;
                }
            },
            mode: 'index',
            intersect: true
        }
    }
}