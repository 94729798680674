import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
const Back = () => {
    const history = useHistory();
    return (
        <React.Fragment>
            <button type='button' className='btn btn-outline-secondary btn-block' onClick={() => history.goBack()}>
                <FontAwesomeIcon icon={faArrowLeft} /> Regresar
            </button>
        </React.Fragment>
    );
}
export default Back;