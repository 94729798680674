import React, { useState, useEffect } from 'react';
import Subtema from './Subtema';
import clientAxios from '../../config/clientAxios';
import { useParams } from 'react-router-dom';
import Cargando from '../extras/Cargando';
import Error from '../extras/Error_500';
import { toSlug } from '../extras/Funciones';
import Back from '../extras/Back';

const Subtemas = () => {
    //Variabels Globales
    const [subsistema, setSubsistema] = useState({});
    const [subtemas, setSubtemas] = useState([]);
    const [status, setStatus] = useState(null);
    //Params
    const { subsistema_id } = useParams();

    useEffect(() => {
        const getSubtemas = async () => {
            await clientAxios.get(`catalogo/getSubtemas/${subsistema_id}`)
                .then(response => {
                    setSubsistema(response.data.subsistema);
                    setSubtemas(response.data.subtemas);
                    setStatus(true);
                }).catch(error => {
                    console.log(error);
                    setStatus(false);
                })
        }
        getSubtemas();
    }, [subsistema_id]);

    const col_size = (index) => {
        /* eslint eqeqeq: 0 */
        if((subtemas.length%2) > 0 && (index == subtemas.length)){
            return true;
        }else{
            return false;
        }
    }

    return (
        <React.Fragment>
            <div className="container animated fadeIn">
                <title>SIEM | COEPLA</title>
                <br></br>
                <div className='row'>
                    <div className='col-lg-3 content-left'>
                        <Back/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {status === true ?
                        <div className='row'>
                            <div className="col-lg-12">
                                <div className="row ">
                                    <div className="col-lg-12 mb-3 text-center">
                                        <br></br>
                                        <span align="center " className={`btn btn-block btn-${toSlug(subsistema.nombre)}`}>
                                            <h3>
                                                <img src={'/images/icons/' + subsistema.file_name + '.png'} width='9%' height='9%' alt={subsistema.name}></img>
                                                {subsistema.id}.-{subsistema.nombre}
                                            </h3>
                                        </span>
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <hr></hr>
                                    </div>
                                </div>
                                <div className='row'>
                                    {subtemas.map((s, index) => {
                                        return (
                                            <Subtema
                                                titulo={s.name}
                                                icono={s.file_name}
                                                tema={toSlug(subsistema.nombre)}
                                                key={s.id}
                                                id={s.id}
                                                flag={col_size(index+1)}>
                                            </Subtema>
                                        );
                                    })}
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <br></br>
                                </div>
                            </div>
                        </div>
                        : status === false ? <Error></Error> : <Cargando></Cargando>
                    }
                </div>
            </div>
        </React.Fragment>
    );
}

export default Subtemas;
