import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/gallery/logos/Logo';
import '../../assets/css/Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="row text-white" style={{ background: '#525251' }}>
                <div className="col-lg-3 text-center align-self-center mb-3 mt-3">
                    <a href="https://www.zacatecas.gob.mx/" target="_blank" rel="noopener noreferrer">
                        <Logo
                            key='logo_zacatecas'
                            src='logo_zacatecas.png'
                            alt='Logo Zacatecas'
                            width='48%'
                            height='48%'
                        ></Logo>
                    </a>
                </div>
                <div className="col-lg-3 mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-12 h4">Contacto</div>
                        <div className="col-lg-12">Cordinación Estatal de Planeación</div>
                        <div className="col-lg-12">Circuito Cerro del Gato s/n</div>
                        <div className="col-lg-12">Colonia Ciudad Gobierno</div>
                        <div className="col-lg-12">Edificio H | Segundo piso</div>
                        <div className="col-lg-12">C.P. 98160</div>
                        <div className="col-lg-12">Zacatecas, Zac.</div>
                    </div>
                </div>
                <div className="col-lg-3 mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-12 h4">Teléfonos COEPLA</div>
                        <br></br><br></br>
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faPhone} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;Tel. (01-492) 4915000,
                        </div>
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faPhone} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;Directo: (01-492) 4915052
                            <br></br>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ext. 10400 Fax.10401
                        </div>
                        <div className='col-lg-12'>&nbsp;</div>
                        <div className='col-lg-12'>
                            <span className="badge badge-pill badge-secondary">
                                <FontAwesomeIcon icon={faEnvelope} size="1x"></FontAwesomeIcon>
                            </span>
                            &nbsp;coepla@zacatecas.gob.mx
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 mb-3 mt-3">
                    <div className="row">
                        <div className="col-lg-12 h4">Teléfonos</div>
                        <div className="col-lg-8">
                            <a href="tel://4929223005">
                                <span className="badge badge-pill badge-secondary">922 3005</span>
                            </a>
                            &nbsp;Cruz Roja Mexicana
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4929225350">
                                <span className="badge badge-pill badge-secondary">922 5350</span>
                            </a>
                            &nbsp;Bomberos
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4029220180">
                                <span className="badge badge-pill badge-secondary">922 0180</span>
                            </a>

                            &nbsp;Policía Estatal Preventiva
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4929245320">
                                <span className="badge badge-pill badge-secondary">924 5320</span>
                            </a>
                            &nbsp;Tránsito del Estado
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://4924915000">
                                <span className="badge badge-pill badge-secondary">491 5000</span>
                            </a>
                            &nbsp;Ciudad Administrativa
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://911" >
                                <span className="badge badge-pill badge-secondary">911</span>
                            </a>
                            &nbsp;Emergencias
                        </div>
                        <div className="col-lg-8">
                            <a href="tel://089">
                                <span className="badge badge-pill badge-secondary">089</span>
                            </a>
                            &nbsp;Denuncia Anónima
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;