import { combineReducers } from "redux";

import General from './GeneralReducer';

//para buscar el TYPE en todos los reducer

//Aqui se agregan todos los reducers
const rootReducer = combineReducers({
    General
});

export default rootReducer;