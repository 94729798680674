import React, { useEffect, useState } from 'react';
import clientAxios from '../../../config/clientAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';
import Error from '../../extras/Error_500';
import Cargando from '../../extras/Cargando';
//redux
import { setMunicipio, setYear, setColumn } from '../../../redux/actions/General.actions';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleCurrencyFormat } from '../../extras/functions/CurrencyFormat';
import '../../../assets/css/Tabla.css';

const Tabla = ({ globals, years, columns, rango, symbols }) => {
    //redux
    const dispatch = useDispatch();
    const { year } = useSelector(state => state.General);
    const { column } = useSelector(state => state.General);
    //const { municipio } = useSelector(state => state.General);
    //listados && locally
    const [status, setStatus] = useState(undefined);
    const [datos, setDatos] = useState([]);
    const [listado, setListado] = useState([]);
    const [search, setSearch] = useState('');
    const [orderAsc, setOrderAsc] = useState(false);
    //css
    var cursorTr = { cursor: 'pointer' };

    //change year
    useEffect(() => {
        const getData = async () => {
            setSearch('');
            if (year) {
                await clientAxios.get(`${globals.route}/table/${year}`, { timeout: 10000 })
                    .then(response => {
                        setDatos(response.data.data);
                        setListado(response.data.data);
                        setStatus(true);
                    }).catch(error => {
                        console.log(error);
                        setStatus(false);
                    })
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year]);
    //Change search word
    useEffect(() => {
        const busqueda = () => {
            if (search !== '') {
                let searching = datos.filter(municipio => municipio.municipio.toLowerCase().indexOf(search.toLowerCase()) >= 0);
                if (searching.length > 0) {
                    setListado(searching);
                } else {
                    setListado([]);
                }
            } else {
                setListado(datos);
            }
        }
        busqueda();
        // eslint-disable-next-line
    }, [search]);
    //function to order
    const orderColumn = (col) => {
        let sortedList;
        if (orderAsc) {
            sortedList = [...listado].sort((a, b) => (parseFloat(a[col]) > parseFloat(b[col]) ? 1 : parseFloat(a[col]) < parseFloat(b[col]) ? -1 : 0));
            setOrderAsc(false);
        } else {
            sortedList = [...listado].sort((a, b) => (parseFloat(a[col]) < parseFloat(b[col]) ? 1 : parseFloat(a[col]) > parseFloat(b[col]) ? -1 : 0));
            setOrderAsc(true);
        }
        setListado(sortedList);
    }

    return (
        <div className='col-md-8' style={{ overflow: 'scroll' }}>
            <div className='row'>
                {status === true &&
                    <React.Fragment>
                        <div className='col-lg-3'>
                            <div className="mb-1">
                                <select className="custom-select" onChange={(e) => dispatch(setYear(e.target.value))} value={year !== null ? year : 0}>
                                    {years.map((y) => {
                                        return (
                                            <option value={y} key={y}>{y}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-3'>
                            <div className="mb-1">
                                <select className="custom-select" onChange={(e) => dispatch(setColumn({ key: e.target.value, value: columns[e.target.value] }))} value={column.key !== null ? column.key : 0}>
                                    {Object.keys(columns).map(function (key) {
                                        return (
                                            <option value={key} key={key}>{columns[key]}</option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-lg-3'></div>
                        <div className='col-lg-3'>
                            <input
                                className='form-control mb-1'
                                placeholder='Buscar'
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            ></input>
                        </div>
                    </React.Fragment>
                }
                {status === true ?
                    <React.Fragment>
                        <div style={{ width: '99%' }}>
                            <div className='table-wrapper-scroll-y my-custom-scrollbar'>
                                <table className="table table-bordered table-striped mb-0">
                                    <thead>
                                        <tr className='thead-dark'>
                                            <th className='text-center' onClick={() => orderColumn('clave')} style={cursorTr}>Municipio &nbsp; <FontAwesomeIcon icon={faSort} /></th>
                                            {Object.keys(columns).map(function (key) {
                                                return (
                                                    <th className='text-center' style={cursorTr} onClick={() => orderColumn(key)} key={key}>
                                                        {columns[key]} <FontAwesomeIcon icon={faSort} />
                                                    </th>
                                                );
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(listado.length > 0) ?
                                            listado.map((list) => {
                                                return (
                                                    <tr key={list.municipio_id}>
                                                        <td>
                                                            <button className='btn btn-link' onClick={() => dispatch(setMunicipio({ id: list.municipio_id, clave: list.clave, nombre: list.municipio }))} style={{ color: '#730D14' }}>
                                                                <strong>{list.clave} - {list.municipio}</strong>
                                                            </button>
                                                        </td>
                                                        {Object.keys(columns).map(function (key) {
                                                            return (
                                                                <td className='text-center' key={list.municipio_id + key}>
                                                                    {!isNaN(list[key]) ?
                                                                        symbols[key] == '%' ? `${(Math.round(list[key] * 100) / 100).toFixed(2)} ${symbols[key]}` : `${SimpleCurrencyFormat.format(list[key])} ${symbols[key]}` :
                                                                        list[key]
                                                                    }
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })
                                            : <tr>
                                                <td colSpan='4' className='text-center'>Sin registros</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </React.Fragment>
                    : status === false ? <Error /> : <Cargando />
                }
            </div>
        </div>
    );
}

export default Tabla;