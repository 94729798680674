import { createStore, applyMiddleware, compose } from 'redux';
import { loadState, saveState } from './saveStore';
import thunk from 'redux-thunk';
import reducer from '../src/redux/reducer/index';

const initialState = loadState();

const middleware = applyMiddleware(thunk);

const store = createStore(
    reducer,
    initialState,
    compose(middleware/*, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()*/)
);

store.subscribe(function () {
    saveState(store.getState());
});

export default store;