import React from 'react';
import { useSelector } from 'react-redux';

const InfoMap = ({ items, title }) => {
    //redux
    const { column } = useSelector(state => state.General);
    const { year } = useSelector(state => state.General);
    const { month } = useSelector(state => state.General);
    
    return (
        <div className="leaflet-bottom leaflet-left">
            <div className="leaflet-control"
                style={{ padding: '6px 8px', font: '14px/16px Arial, Helvetica, sans-serif', background: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.2)', borderRadius: '5px', opacity: 0.7 }}>
                {title &&
                    <h6 style={{ color: '#777' }} className='text-center'>{`(${column.value}) ${month.nombre} ${year}`}</h6>
                }
                {items.map((item) => {
                    return (
                        <React.Fragment key={item.color}>
                            <span className='badge badge-pill' style={{ background: item.color }}>
                                {item.total < 10 &&
                                    <React.Fragment>
                                        &nbsp;
                                    </React.Fragment>
                                }
                                <strong style={{ color: 'white' }}>{ item.total }</strong>
                            </span>
                            &nbsp;{item.range}
                            <br></br>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}

export default InfoMap;