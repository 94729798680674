import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Subsistemas from "./components/subsistemas/Subsistemas";
import Menu from "./components/menu/Menu";
import Footer from "./components/footer/Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Subtemas from "./components/subtemas/Subtemas";
import ListadoInformacion from "./components/informacion/ListadoInformacion";
import NotFound from "./components/extras/NotFound";
import "./assets/css/Buttons.css";
import Nacimientos from "./components/listado/nacimientos/Nacimientos";
import Poblacion from "./components/listado/poblacion/Poblacion";
import Defuncion from "./components/listado/defunciones/Defuncion";
import Accidentes from "./components/listado/accidentes_transito/Accidentes";
import Matrimonios from "./components/listado/matrimonios/Matrimonios";
import Remesas from "./components/listado/vivienda_remesas/Remesas";
import UnidadEconomica from "./components/listado/unidades_economicas/UnidadEconomica";
import ResiduosSolidosRecolectados from "./components/listado/residuos_solidos_recolectados/ResiduosSolidosRecolectados";
import Delito from "./components/listado/delitos_fuero_comun/Delito";
import Pobreza from "./components/listado/pobreza/Pobreza";
import PobrezaModerada from "./components/listado/pobreza/PobrezaModerada";
import PobrezaExtrema from "./components/listado/pobreza/PobrezaExtrema";
import ProduccionAgricola from "./components/listado/produccion_agricola/ProduccionAgricola";
import ProduccionFrijol from "./components/listado/produccion_frijol/ProduccionFrijol";
import Pea from "./components/listado/pea/Pea";
import Incendios from "./components/listado/incendios/Incendios";
import DenunciaAmbiental from "./components/listado/denuncias_ambientales/DenunciaAmbiental";
import Poblacion_discapacitada from "./components/listado/poblacion_discapacitada/Poblacion_discapacitada";
import CondicionAlfabetismo from "./components/listado/condicion_alfabetismo/CondicionAlfabetismo";
import GradoEscolaridad from "./components/listado/grado_escolaridad/GradoEscolaridad";
import TipoHogar from "./components/listado/tipos_hogar/TipoHogar";
import DisponibilidadBienes from "./components/listado/disponibilidad_bienes/DisponibilidadBienes";
import RezagoEducativo from "./components/listado/rezago_educativo/RezagoEducativo";
import ProyeccionPoblacion from "./components/listado/proyeccion_poblacion/ProyeccionPoblacion";
import LugarResidencia from "./components/listado/lugar_residencia/LugarResidencia";
import Carreteras from "./components/listado/carreteras/Carreteras";
import IntensidadMigratoria from "./components/listado/intensidad_migratoria/IntensidadMigratoria";
import GradoMarginacion from "./components/listado/grado_marginacion/GradoMarginacion";
import DensidadPoblacion from "./components/listado/densidad_poblacion/DensidadPoblacion";
import PercepcionRemesas from "./components/listado/percepcion_remesas/PercepcionRemesas";
import Gacp from "./components/listado/gacp/Gacp";
import SituacionConyugal from "./components/listado/situacion_conyugal/SituacionConyugal";
import AsistenciaEscolar from "./components/listado/asistencia_escolar/AsistenciaEscolar";
import ViolenciaMujeres from "./components/listado/vieolencia_mujeres/ViolenciaMujeres";
import ModalidadViolenciaMujeres from "./components/listado/modalidad_vieolencia_mujeres/ModalidadViolenciaMujeres";
import AfiliacionSS from "./components/listado/afiliacion_servicios_salud/AfiliacionSS";
import SalarioPromedio from "./components/listado/salario_promedio/SalarioPromedio";
import AseguradoImss from "./components/listado/asegurados_imss/AseguradosImss";
import Fecundidad_adolecentes from "./components/listado/fecundidad_adolecentes/Fecundidad_adolecentes";
import Ocupantes_viviendas_particulares_habitadas from "./components/listado/ocupantes-viviendas-particulares-habitadas/Ocupantes_viviendas_particulares_habitadas";
import AgriculturaProtegida from "./components/listado/agricultura_protegida/AgriculturaProtegida";
import ProduccionAjo from "./components/listado/produccion_ajo/ProduccionAjo";
import ProduccionUva from "./components/listado/produccion_uva/ProduccionUva";
import Delitos_anual from "./components/listado/delitos_fuero_comun_anual/Delitos_anual";

import RelacionHM from "./components/listado/sector_comercial/SectorComercial";
import SectorComercial from "./components/listado/sector_comercial/SectorComercial";
import SectorManufacturero from "./components/listado/sector_manufacturero/SectorManufacturero";
import SectorAlojamiento from "./components/listado/sector_alojamiento/SectorAlojamiento";
import IndicadoresLaborales from "./components/listado/indicadores_laborales/IndicadoresLaborales";

const App = () => {
	return (
		<Router>
			<Menu></Menu>
			<Switch>
				<Route exact path="/" component={Subsistemas}></Route>
				<Route
					exact
					path="/subtemas/:subsistema_id"
					component={Subtemas}
				></Route>
				<Route
					exact
					path="/informacion/:subtema_id"
					component={ListadoInformacion}
				></Route>
				{/* Rutas para cargar la información */}
				<Route exact path="/nacimientos" component={Nacimientos}></Route>
				<Route
					exact
					path="/accidentes-de-transito-terrestre"
					component={Accidentes}
				></Route>
				<Route
					exact
					path="/condicion-de-alfabetismo"
					component={CondicionAlfabetismo}
				></Route>
				<Route exact path="/defunciones" component={Defuncion}></Route>
				<Route exact path="/delitos-del-fuero-comun" component={Delito}></Route>
				<Route
					exact
					path="/numero-de-denuncias-ambientales-recibidas"
					component={DenunciaAmbiental}
				></Route>
				<Route
					exact
					path="/disponibilidad-de-bienes-y-tic"
					component={DisponibilidadBienes}
				></Route>
				<Route
					exact
					path="/grado-promedio-de-escolaridad"
					component={GradoEscolaridad}
				></Route>
				<Route
					exact
					path="/incendios-forestales-y-superficie-afectada"
					component={Incendios}
				></Route>
				<Route exact path="/matrimonios" component={Matrimonios}></Route>
				<Route
					exact
					path="/poblacion-economicamente-activa"
					component={Pea}
				></Route>
				<Route exact path="/poblacion" component={Poblacion}></Route>
				<Route
					exact
					path="/poblacion-con-discapacidad"
					component={Poblacion_discapacitada}
				></Route>
				<Route exact path="/pobreza" component={Pobreza}></Route>
				<Route
					exact
					path="/pobreza-moderada"
					component={PobrezaModerada}
				></Route>
				<Route exact path="/pobreza-extrema" component={PobrezaExtrema}></Route>
				<Route
					exact
					path="/produccion-agricola"
					component={ProduccionAgricola}
				></Route>
				<Route
					exact
					path="/produccion-de-frijol-en-el-estado"
					component={ProduccionFrijol}
				></Route>
				<Route
					exact
					path="/residuos-solidos-recolectados"
					component={ResiduosSolidosRecolectados}
				></Route>
				<Route
					exact
					path="/tipo-y-clase-de-hogar"
					component={TipoHogar}
				></Route>
				<Route
					exact
					path="/unidades-economicas"
					component={UnidadEconomica}
				></Route>
				<Route
					exact
					path="/porcentaje-de-viviendas-que-reciben-remesas"
					component={Remesas}
				></Route>
				<Route
					exact
					path="/poblacion-en-rezago-educativo"
					component={RezagoEducativo}
				></Route>
				<Route
					exact
					path="/proyecciones-de-poblacion"
					component={ProyeccionPoblacion}
				></Route>
				<Route
					exact
					path="/poblacion-por-municipio-de-residencia-actual-y-lugar-de-nacimiento"
					component={LugarResidencia}
				></Route>
				<Route
					exact
					path="/longitud-de-carretera-estatal"
					component={Carreteras}
				></Route>
				<Route
					exact
					path="/indice-de-intensidad-migratoria"
					component={IntensidadMigratoria}
				></Route>
				<Route
					exact
					path="/grado-de-marginacion"
					component={GradoMarginacion}
				></Route>
				<Route
					exact
					path="/densidad-de-poblacion"
					component={DensidadPoblacion}
				></Route>
				<Route
					exact
					path="/promedio-de-percepcion-de-remesas-per-capita-dolares"
					component={PercepcionRemesas}
				></Route>{" "}
				example
				<Route
					exact
					path="/grado-de-accesibilidad-a-carretera-pavimentada"
					component={Gacp}
				></Route>
				<Route
					exact
					path="/situacion-conyugal"
					component={SituacionConyugal}
				></Route>
				<Route
					exact
					path="/asistencia-escolar"
					component={AsistenciaEscolar}
				></Route>
				<Route
					exact
					path="/casos-registrados-segun-el-tipo-de-violencia-hacia-las-mujeres"
					component={ViolenciaMujeres}
				></Route>
				<Route
					exact
					path="/casos-registrados-segun-la-modalidad-de-violencia-hacia-las-mujeres"
					component={ModalidadViolenciaMujeres}
				></Route>
				<Route
					exact
					path="/condicion-de-afiliacion-a-los-servicios-de-salud"
					component={AfiliacionSS}
				></Route>
				<Route
					exact
					path="/salario-promedio"
					component={SalarioPromedio}
				></Route>
				<Route
					exact
					path="/trabajadores-asegurados-al-imss"
					component={AseguradoImss}
				></Route>
				<Route
					exact
					path="/tasa-de-fecundidad-adolescente"
					component={Fecundidad_adolecentes}
				></Route>
				<Route
					exact
					path="/promedio-de-ocupantes-en-viviendas-particulares-habitadas"
					component={Ocupantes_viviendas_particulares_habitadas}
				></Route>
				<Route
					exact
					path="/agricultura-protegida"
					component={AgriculturaProtegida}
				></Route>
				<Route
					exact
					path="/produccion-de-ajo-en-el-estado"
					component={ProduccionAjo}
				></Route>
				<Route
					exact
					path="/produccion-de-uva-en-el-estado"
					component={ProduccionUva}
				></Route>
				<Route
					exact
					path="/delitos-del-fuero-comun-anual"
					component={Delitos_anual}
				></Route>
				<Route
					exact
					path="/situacion-conyugal"
					component={SituacionConyugal}
				></Route>
				<Route
					exact
					path="/relacion-hombre-mujer"
					component={RelacionHM}
				></Route>
				<Route
					exact
					path="/porcentaje-de-ocupacion-en-el-sector-del-comercio-al-por-menor"
					component={SectorComercial}
				></Route>
				<Route
					exact
					path="/porcentaje-de-ocupacion-en-el-sector-de-las-manufacturas"
					component={SectorManufacturero}
				></Route>
				<Route
					exact
					path="/porcentaje-de-ocupacion-en-el-sector-servicios-de-alojamiento-temporal-y-de-preparacion-de-alimentos"
					component={SectorAlojamiento}
				></Route>
				<Route
					exact
					path="/indicadores-laborales"
					component={IndicadoresLaborales}
				></Route>
				{/* Rutas para cargar la información */}
				<Route component={NotFound}></Route>
			</Switch>
			<Footer></Footer>
		</Router>
	);
};

export default App;
