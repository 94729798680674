
export const loadState = () => {
    try {
        const Data = localStorage.getItem('state');
        if(Data === null){
            return undefined;
        }else{
            return JSON.parse(Data);
        }
    } catch(error) {
        return undefined;
    }
}

export const saveState = (state) => {
    try{
        let Data = JSON.stringify(state);
        localStorage.setItem('state', Data);
    } catch(error){
        console.log(error);
    }
}