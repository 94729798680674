import React, { useState, useEffect } from 'react';
import clientAxios from '../../config/clientAxios';
import Subsistema from './Subsistema';
import Cargando from '../extras/Cargando';
import Error from '../extras/Error_500';
import { toSlug } from '../extras/Funciones';

const Subsistemas = () => {

    const [subsistemas, setSubsistemas] = useState([]);
    const [status, setStatus] = useState(undefined);

    useEffect(() => {
        const getSubsistemas = async () => {
            await clientAxios.get('catalogo/getSubsistemas', { timeout: 10000 })
                .then(response => {
                    setSubsistemas(response.data.subsistemas);
                    setStatus(true);
                }).catch(error => {
                    console.log(error);
                    setStatus(false);
                })
        }
        getSubsistemas();
    }, []);

    return (
        <React.Fragment>
            <div className="container animated fadeIn">
                <title>SIEM | COEPLA</title>
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="row ">
                            <div className="col-lg-12 mb-3 text-center">
                                <br></br>
                                <div className="text-dark"></div>
                            </div>
                            <div className="col-lg-12 mb-3">
                                <hr></hr>
                            </div>
                        </div>
                        <div className='row'>
                            {status === true ?
                                subsistemas.map(s => {
                                    return (
                                        <Subsistema
                                            titulo={s.name}
                                            icono={s.file_name}
                                            tema={toSlug(s.name)}
                                            key={s.id}
                                            id={s.id}>
                                        </Subsistema>
                                    )
                                }) : status === false ? <Error></Error> : <Cargando></Cargando>
                            }
                        </div>
                        <div className="row ">
                            <div className="col-lg-12 mb-3 text-center">
                                <br></br>
                                <div className="text-dark"></div>
                            </div>
                            <div className="col-lg-12 mb-3">
                                <hr></hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br></br>
        </React.Fragment>
    );
}

export default Subsistemas;
