import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import clientAxios from '../../../config/clientAxios';
import { getClave } from '../../extras/Funciones';
import Cargando from '../../extras/Cargando';
import { useDispatch, useSelector } from 'react-redux';
import { setMunicipio, resetMunicipio, setScroll } from '../../../redux/actions/General.actions';
import { SimpleCurrencyFormat } from '../../extras/functions/CurrencyFormat';

const BarChart = ({ globals, symbols }) => {

    const dispatch = useDispatch();
    const { year } = useSelector(state => state.General);
    const { column } = useSelector(state => state.General);

    const [objectBar, setObjectBar] = useState({
        datasets: [{
            data: [],
            backgroundColor: [],
            type: null
        }],
        labels: null
    });
    const options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            yAxes: [{
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    beginAtZero: globals.atZero,
                    callback: function (value, index, values) {
                        return SimpleCurrencyFormat.format(value);
                    }
                }
            }]
        },
        legend: {
            display: false,
        },
        onClick: function (e, item) {
            if (item.length > 0) {
                dispatch(setScroll());
                dispatch(resetMunicipio());
                const data = (item[0]._model.label).split(".-", 2);
                let mun = {
                    id: parseFloat(data[0]),
                    clave: getClave(data[0]),
                    nombre: data[1]
                };
                dispatch(setMunicipio(mun));
            }
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem) {
                    if (!isNaN(tooltipItem.yLabel)) {
                        if (symbols[column.key] == '%') {
                            return `${column.value}: ${(Math.round(tooltipItem.yLabel * 100) / 100).toFixed(2)} ${symbols[column.key]}`;
                        } else if (column.key === 'grado') {
                            return `${column.value}: ${grado(tooltipItem.yLabel)}`;
                        } else {
                            return `${column.value}: ${SimpleCurrencyFormat.format(tooltipItem.yLabel)} ${symbols[column.key]}`;
                        }
                    } else {
                        return `${column.value}: ${tooltipItem.yLabel} ${symbols[column.key]}`;
                    }
                }
            },
            mode: 'index',
            intersect: true
        }
    };

    const grado = (id) => {
        switch (id) {
            case 1: return "Muy bajo";
            case 2: return "Bajo";
            case 3: return "Medio";
            case 4: return "Alto";
            default: return '';
        }
    }

    const [status, setStatus] = useState(false);
    const [width, setWidth] = useState('100%');
    const [height, setHeight] = useState('100vw');

    useEffect(() => {
        const getDataSet = async () => {
            setStatus(false);
            if (year) {
                await clientAxios.get(`${globals.route}/graphBar/${year}/${column.key}`, { timeout: 10000 })
                    .then(response => {
                        setObjectBar(response.data.objectBar);
                        setStatus(true);
                        setWidth('300%');
                        setHeight('25vw');
                    })
                    .catch(error => {
                        setWidth('100%');
                        setHeight('100%');
                        console.error(error);
                        setStatus(false);
                    });
            }
        }
        getDataSet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, column]);

    return (
        <div className='grafica_barras' style={{ width: width, height: height }}>
            {status === true ?
                <Bar
                    data={objectBar}
                    options={options}
                /> : <Cargando></Cargando>
            }
        </div>
    );
}

export default BarChart;