import React, { useEffect, useState } from "react";
import Mapa from "../general/mapa/Mapa";
import BarChart from "../general/graficas/BarChart";
import clientAxios from "../../../config/clientAxios";
import Tabla from "../general/Tabla";
import Back from "../../extras/Back";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/free-solid-svg-icons";
//Redux
import {
	setColumn,
	resetColumn,
	resetMunicipio,
	setYear,
	resetScroll,
} from "../../../redux/actions/General.actions";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import LineChart from "../general/graficas/LineChart";

const IndicadoresLaborales = () => {
	const globals = {
		route: "indicadores-laborales",
		title: "Indicadores Laborales",
		atZero: true,
	};

	//Redux
	const dispatch = useDispatch();
	const { municipio } = useSelector((state) => state.General);
	const { year } = useSelector((state) => state.General);
	const { column } = useSelector((state) => state.General);
	const { scroll } = useSelector((state) => state.General);

	//Locally
	const [years, setYears] = useState([]);
	const [columns, setColumns] = useState([]);
	const [rango, setRango] = useState({ min: 0, max: 0 });
	const [symbols, setSymbols] = useState([]);
	const [status, setStatus] = useState(undefined);

	useEffect(() => {
		if (scroll) {
			window.scrollTo(0, 0);
		}
		dispatch(resetScroll());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [scroll]);

	//inital
	useEffect(() => {
		dispatch(resetMunicipio());
		dispatch(resetColumn());
		const getColums = async () => {
			await clientAxios
				.get(`${globals.route}/initial`, { timeout: 10000 })
				.then((response) => {
					dispatch(setYear(response.data.maximo));
					setYears(response.data.years);
					setColumns(response.data.attributes);
					dispatch(
						setColumn({
							key: Object.keys(response.data.attributes)[0],
							value:
								response.data.attributes[
									Object.keys(response.data.attributes)[0]
								],
						})
					);
					setRango({ min: response.data.minimo, max: response.data.maximo });
					setSymbols(response.data.symbols);
					setStatus(true);
				})
				.catch((error) => {
					setStatus(false);
					console.log(error);
				});
		};
		getColums();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<React.Fragment>
			<div className="row">
				<div className="col-lg-2 mt-2 mb-3">
					<Back />
				</div>
				<div className="col-lg-7" style={{ textAlign: "center" }}>
					<h2>
						{globals.title} &nbsp;
						{/* eslint-disable-next-line */}
						{status === true
							? //eslint-disable-next-line
							  year == 0
								? `(${rango.min} - ${rango.max})`
								: `${year}`
							: ""}
					</h2>
				</div>
			</div>
			<div className="row" id="mapa">
				<div className="col-md-4">
					<Mapa globals={globals} symbols={symbols} />
				</div>
				{!municipio.clave ? (
					<Tabla
						globals={globals}
						years={years}
						columns={columns}
						rango={rango}
						symbols={symbols}
					/>
				) : (
					<div className="col-lg-8">
						<div className="row justify-content-md-center">
							<div className="col-lg-3">
								<button
									type="button"
									className="btn btn-outline-secondary btn-sm"
									onClick={() => dispatch(resetMunicipio())}
								>
									<FontAwesomeIcon icon={faBackspace} /> Listado Municipios
								</button>
							</div>
							<div className="col-lg-6 text-center">
								<h4>
									{municipio.id}.-{municipio.nombre}
								</h4>
							</div>
							<div className="col-lg-3"></div>
						</div>
						<br></br>
						<Tabs defaultActiveKey="incendios" id="incendios" className="mb-3">
							<Tab eventKey="incendios" title="Incendios">
								<LineChart
									globals={globals}
									columns={{ incendios: columns["incendios"] }}
								/>
							</Tab>
							<Tab eventKey="superficie" title="Superficie">
								<LineChart
									globals={globals}
									columns={{ superficie: columns["superficie"] }}
								/>
							</Tab>
						</Tabs>
					</div>
				)}
				<div className="col-lg-12">
					<div className="row justify-content-md-center">
						<div className="col-lg-4 text-center">
							{/*eslint-disable-next-line*/}
							<strong>
								{globals.title} ({column.value})
							</strong>{" "}
							&nbsp;
							<i>
								<small>
									({year == 0 ? `${rango.min} - ${rango.max}` : `${year}`})
								</small>
							</i>
						</div>
					</div>
				</div>
				<div className="col-md-12" style={{ overflow: "scroll" }}>
					<BarChart globals={globals} symbols={symbols} />
				</div>
			</div>
		</React.Fragment>
	);
};

export default IndicadoresLaborales;
