import React, { useEffect, useState } from 'react';
import { dataGeoJson } from '../../../extras/Geojson';
import { getClave } from '../../../extras/Funciones';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import clientAxios from '../../../../config/clientAxios';
import InfoMap from './InfoMap';
import Cargando from '../../../extras/Cargando';
import { setMunicipio as setMun, resetMunicipio, setScroll } from '../../../../redux/actions/General.actions';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleCurrencyFormat } from '../../../extras/functions/CurrencyFormat';

const Mapa = ({ globals, symbols }) => {
    const [municipio, setMunicipio] = useState({});
    const [colors, setColors] = useState([]);
    const [rangos, setRangos] = useState([]);
    const [totales, setTotales] = useState([]);
    const [status, setStatus] = useState(false);
    const dispatch = useDispatch();
    
    //redux
    const { year } = useSelector(state => state.General);
    const { month } = useSelector(state => state.General);
    const { column } = useSelector(state => state.General);

    useEffect(() => {
        const getData = async () => {
            if (column.key !== null && year !== null) {
                setStatus(false);
                await clientAxios.get(`${globals.route}/mapa/${year}/${month.id}/${column.key}`, { timeout: 10000 })
                    .then(response => {
                        setColors(response.data.colors);
                        setRangos(response.data.rangos);
                        setTotales(response.data.totales);
                        setStatus(true);
                    })
                    .catch(error => {
                        console.error(error);
                        setStatus(false);
                    });
            }
        }
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [year, month, column]);

    return (
        <React.Fragment>
            {status === true ?
                <div className='mapa'>
                    <MapContainer style={{ "width": "32vw", "height": "82vh" }} center={['22.818146', '-102.458302']} zoom={7} minZoom={6} maxZoom={11} touchZoom={-1} zoomControl={true} scrollWheelZoom={false}>
                        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
                        <div className="leaflet-top leaflet-right">
                            <div className="info leaflet-control text-center" style={{ padding: '6px 8px', font: '14px/16px Arial, Helvetica, sans-serif', background: 'white', boxShadow: '0 0 15px rgba(0,0,0,0.2)', borderRadius: '5px', opacity: 0.7 }}>
                                <h6 style={{ color: '#777' }}>Zacatecas, México</h6>
                                {municipio.clave &&
                                    <div>
                                        <strong>{municipio.clave} - {municipio.nombre}</strong><br></br>
                                        <label>Región: {municipio.region}</label>
                                    </div>
                                }
                            </div>
                        </div>
                        <InfoMap
                            items={rangos}
                            title={globals.title}
                        ></InfoMap>
                        {dataGeoJson.features.map(dato => {
                            return (
                                <GeoJSON
                                    key={dato.properties.CVE_MUN}
                                    data={dato}
                                    eventHandlers={{
                                        mouseover: (e) => {
                                            e.layer.setStyle({
                                                fillOpacity: 1
                                            });
                                            let value = '';
                                            if (!isNaN(totales[dato.properties.CVE_MUN - 1])) {
                                                if (symbols[column.key] == '%') {
                                                    value = `${(Math.round(totales[dato.properties.CVE_MUN - 1] * 100) / 100).toFixed(2)} ${symbols[column.key]}`;
                                                } else if (symbols[column.key] == '$') {
                                                    value = `${symbols[column.key]}${SimpleCurrencyFormat.format(totales[dato.properties.CVE_MUN - 1])}`;
                                                } else {
                                                    value = `${SimpleCurrencyFormat.format(totales[dato.properties.CVE_MUN - 1])} ${symbols[column.key]}`;
                                                }
                                            } else {
                                                value = `${totales[dato.properties.CVE_MUN - 1]} ${symbols[column.key]}`;
                                            }
                                            var contentPopUp = `${dato.properties.NOM_MUN} <strong> ${value} </strong>`;
                                            e.propagatedFrom.bindTooltip(contentPopUp).openTooltip();
                                            setMunicipio({
                                                clave: getClave(dato.properties.CVE_MUN),
                                                nombre: dato.properties.NOM_MUN,
                                                region: dato.properties.NOM_REG
                                            });
                                        },
                                        mouseout: (e) => {
                                            setMunicipio({});
                                            e.layer.setStyle({
                                                fillOpacity: 0.6
                                            });
                                        },
                                        click: (e) => {
                                            dispatch(setScroll());
                                            let mun = {
                                                id: parseInt(dato.properties.CVE_MUN),
                                                clave: getClave(dato.properties.CVE_MUN),
                                                nombre: dato.properties.NOM_MUN
                                            };
                                            dispatch(resetMunicipio());
                                            dispatch(setMun(mun));
                                        },
                                    }}
                                    color={'white'}
                                    fillColor={colors[dato.properties.CVE_MUN - 1]}
                                    fillOpacity={0.6}
                                    dashArray={'3'} >
                                </GeoJSON>
                            );
                        })}
                    </MapContainer>
                </div>
                : <Cargando></Cargando>}
        </React.Fragment>
    );
}

export default Mapa;