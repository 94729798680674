import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Subsistema = ({ titulo, icono, tema, id }) => {
    //Población #f2516c primary
    //Economico #f4c334 warning
    //Ambiente #72ceae success
    //Seguridad #75b196 danger
    return (
        <div className="col-lg-6 mb-3 mt-3">
            <div className="btn-group btn-group-md btn-block align-middle" role="group">
                <Link to={`/subtemas/${id}`} className={`btn btn-${tema}`} style={{ width: '90%', height: '100%'}}>
                    <img className="img-responsive" src={`/images/icons/${icono}.png`} width='20%' height='100%' alt={titulo}></img>
                    <small>&nbsp;{id}.-{titulo}</small>
                </Link>
                <button className={"btn btn-" + tema} style={{ width: '10%', opacity: 0.8 }}>
                    <FontAwesomeIcon icon={ faInfoCircle } color='white' />
                </button>
            </div>
        </div>
    );
}

export default Subsistema;
