import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Subtema = ({ titulo, icono, tema, id, flag }) => {
    return (
        <React.Fragment>
            {flag &&
                <div className='col-lg-3'></div>
            }
            <div className={"col-lg-6 mb-3 mt-3"}>
                <div className="btn-group btn-group-md btn-block align-middle" role="group" >
                    <Link to={'/informacion/' + id} className={"btn btn-" + tema} style={{ width: '90%' }}>
                        <img className="img-responsive" src={'/images/icons/' + icono + '.png'} width='10%' height='100%' alt={titulo}></img>
                        &nbsp;{titulo} {flag}
                    </Link>
                    <button className={"btn btn-" + tema} style={{ width: '10%', opacity: 0.8 }}>
                        <FontAwesomeIcon icon={faInfoCircle} color='white' />
                    </button>
                </div>
            </div>
            {flag &&
                <div className='col-lg-3'></div>
            }
        </React.Fragment>
    );
}

export default Subtema;
